<template>
  <div class="page">
    <div class="m-container">
      <PrivacyText :lang="lang" />
    </div>
  </div>
</template>

<script>
import PrivacyText from "../../components/Privacy.vue"
export default {
  components: {
    PrivacyText
  },
  computed: {
    lang() {
      let lang = 'zh'
      if (this.$route.query.lang == 'en') {
        lang = 'en'
      }
      return lang
    },
  },
}
</script>

<style lang="scss" scoped>
.page {
  color: #5a5a5a;
}
.m-container {
  max-width: 768px;
  padding: 0 15px;
  margin: 0 auto;
  padding: 15px;
}
</style>
